<template>
  <div class="productEngine">
    <div class="bg">
      <div class="info">
        <h3>星云三维数字基础平台</h3>
        <p>易用<i> </i> 开放<i> </i> 高效<i> </i> 共享</p>
        <!-- <span>登录</span> -->
        <!-- <span @click="to('downloadcenter')">下载</span> -->
      </div>
    </div>
    <div class="xingyun">
      <div class="container">
        <div class="logo"><img src="../../assets/image/logo2.svg" alt=""></div>
        <p class="alignLeft">
星云（Nebula）是秉匠科技全新推出面向泛工程行业的三维数字基础平台，为泛工程行业的信息化平台搭建提供基础、易用、高效和开放的基础开发框架，助力客户低成本、快速搭建具备高性能三维应用能力的业务系统。星云对黑洞图形引擎的底层功能进行了友好封装，可提供高易用的图形低代码能力，同时通过表单、工作流等低代码设计引擎实现业务模块的快速构建。帮助企业和项目完成信息化建设，加速数字化转型升级。</p>
        <div class="iconlist">
          <img src="../../assets/image/bg-0602.png" alt="">
        </div>
        <!-- <ul class="iconlist">
          <li>
            <img src="../../assets/image/loginicon/易用.png" alt="">
            <h4>易用</h4>
            <p>基于浏览器，摆脱客户端，无需安装。</p>
          </li>
          <li>
            <img src="../../assets/image/loginicon/开放.png" alt="">
            <h4>开放</h4>
            <p>支持二次开发，可试用于众多领域，满足更多需求。</p>
          </li>
          <li>
            <img src="../../assets/image/loginicon/高效.png" alt="">
            <h4>高效</h4>
            <p>针对不同规模的工程，支持承载超大模型。</p>
          </li>
          <li>
            <img src="../../assets/image/loginicon/安全.png" alt="">
            <h4>安全</h4>
            <p>针对不同规模的工程，支持承载超大模型。</p>
          </li>
          <li>
            <img src="../../assets/image/loginicon/协同.png" alt="">
            <h4>协同</h4>
            <p>支持多方交互和权限管控。</p>
          </li>
          <li>
            <img src="../../assets/image/loginicon/共享.png" alt="">
            <h4>共享</h4>
            <p>与其他平台共享数据和功能，具有可拓展性。</p>
          </li>
        </ul> -->
      </div>
    </div>
    <div class="animate">
      <div class="container">
        <!-- <div class="circle">
          <div class="three"><img src="../../assets/image/nebula/星云底座/-3.png" alt=""></div>
          <div class="two"><img src="../../assets/image/nebula/星云底座/-2.png" alt=""></div>
          <div class="one"><img src="../../assets/image/nebula/星云底座/-1.png" alt=""></div>
        </div> -->
        <div class="circle" @click="clickcircle"><img src="../../assets/image/nebula/星云底座/total.png" alt=""></div>
        <div :class="{'infobox' : true, infobox1: true, animate__backInLeft: animate, animate__animated: animate }" v-if="infobox1"><p>非底层开发模式</p> 开发更加便捷</div>
        <div :class="{'infobox' : true, infobox2: true, animate__backInLeft: animate, animate__animated: animate }" v-if="infobox2"><p>丰富的业务接口</p> 丰富的图形接口</div>
        <div :class="{'infobox' : true, infobox3: true, animate__backInRight: animate, animate__animated: animate }" v-if="infobox3"><p>模块自由组合</p> 自由搭建平台元素</div>
        <div :class="{'infobox' : true, infobox4: true, animate__backInRight: animate, animate__animated: animate }" v-if="infobox4"><p>适应各种场景</p> 匹配具体业务需求</div>
      </div>
    </div>
    <More/>
  </div>
</template>

<script>
import More from '@/components/product/more'
const infobox1 = false
const infobox2 = false
const infobox3 = false
const infobox4 = false
export default {
  components: {
    More
  },
  data () {
    return {
      infobox1: false,
      infobox2: false,
      infobox3: false,
      infobox4: false,
      animate: false,
      click: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll, true)
  },
  destroy () {
		window.removeEventListener('scroll', this.onScroll, false)
	},

  methods: {
    onScroll () {
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop && scrollTop > 460) {
        this.clickcircle()
      }
		},
    to (path) {
      this.$router.push(path)
    },
    clickcircle () {
      this.infobox1 = true
      this.animate = true
      setTimeout(() => {
        this.infobox3 = true
        setTimeout(() => {
          this.infobox2 = true
          setTimeout(() => {
            this.infobox4 = true
            this.click = !this.click
          }, 1000)
        }, 1000)
      }, 1000)
    },
    showinfo () {
      // i = 1
      // this.infobox = this.infobox
    }
  }
}
</script>

<style lang="less" scoped>
.productEngine{
  // height: 100%;
  color: #fff;
  .bg{
    width: 100%;
    height: 60vh;
    background: url('../../assets/image/product/bg.png') center center no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    .info{
      position: absolute;
      top: 50%;
      left: 80px;
      transform: translateY(-50%);
      h3{
        font-size: 50px;
        font-weight: normal;
        font-family: "siyuan";
        @media screen and (max-width:768px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      span{
        width: 100px;
        height: 40px;
        background-color: #0aaff1;
        display: block;
        line-height: 40px;
        margin-top: 20px;
        text-align: center;
        cursor: pointer;
        float: left;
        margin-right: 10px;
      }
      p{
        font-size: 20px;
        line-height: 50px;
        i {
          margin-right:8px
        }
      }
    }
  }
  .xingyun{
    width: 100%;
    // height: 60vh;
    background: url('../../assets/image/product/bg_engine.jpg') center center no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    text-align: center;
    padding: 60px 0;
    .logo{
      width: 200px;
      margin: 0px auto;
      padding-bottom: 20px;
      img{
        width: 100%;
        object-fit: contain;
      }
    }
    .iconlist{
      margin: 20px 0 0;
      overflow: hidden;
      li{
        width: calc(33% - 10px);
        margin-right: 10px;
        float: left;
        height: 180px;
        border: 1px solid #ccc;
        margin-top: 20px;
        @media screen and (max-width:768px) {
          width: 80%;
          float: none;
          margin: 30px auto;
        }
        @media screen and (min-width:768px) and (max-width:992px) {
          width: 48%;
        }
        img{
          width: 50px;
          margin-top: 30px;
        }
        h4{
          line-height: 30px;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
  .animate{
    // background-color: pink;
    // background: #081F27;
    background: #000;
    position: relative;
    .container{
      .circle{
        padding: 40px 0 80px 0;
        img{
          width: 60%;
          margin: 0 auto;
          display: block;
        }
      }
      .infobox{
        width: 200px;
        border: 3px solid #4060a3;
        border-radius: 10px;
        // color: #254061;
        color: #fff;
        position: absolute;
        padding: 10px;
        font-size: 24px;
        font-weight: 700;
      }
      .infobox1{
        top: 20%;
        left: 5%;
      }
      .infobox2{
        top: 70%;
        left: 5%;
      }
      .infobox3{
        top: 20%;
        right: 5%;
      }
      .infobox4{
        top: 70%;
        right: 5%;
      }
    }
    // .three{
    //   width: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 23;
    //   img{
    //     width: 720px;
    //     margin: 0 auto;
    //     display: block;
    //   }
    // }
    // .two{
    //   width: 100%;
    //   position: absolute;
    //   top: 120px;
    //   left: 0;
    //   z-index: 22;
    //   img{
    //     width: 480px;
    //     margin: 0 auto;
    //     display: block;
    //   }
    // }
    // .one{
    //   width: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 21;
    //   img{
    //     width: 720px;
    //     margin: 0 auto;
    //     display: block;
    //   }
    // }
  }

}
</style>
